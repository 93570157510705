import React from "react";
import './binary-background.css';

const BinaryBackground: React.FC = ({}) => {
    return(
        <div className='binary-numbers'>
                <div className='binary-row'>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                </div>
                <div className='binary-row'>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                </div>
                <div className='binary-row'>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                </div>
                <div className='binary-row'>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                </div>
                <div className='binary-row'>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                </div>
                <div className='binary-row'>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                </div>
                <div className='binary-row'>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                </div>
                <div className='binary-row'>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                </div>
                <div className='binary-row'>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                </div>
                <div className='binary-row'>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                </div>
                <div className='binary-row'>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>0</div>
                    <div className='binary-digits'>1</div>
                    <div className='binary-digits'>1</div>
                </div>
            </div>
    )
}

export default BinaryBackground;