import Justin0 from "../images/execs/1/0.webp";
import Justin1 from "../images/execs/1/1.webp";
import Justin2 from "../images/execs/1/2.webp";
import Justin3 from "../images/execs/1/3.webp";
import Justin4 from "../images/execs/1/4.webp";
import Justin5 from "../images/execs/1/5.webp";
import Justin6 from "../images/execs/1/6.webp";
import Justin7 from "../images/execs/1/7.webp";
import Justin8 from "../images/execs/1/8.webp";
import Justin9 from "../images/execs/1/9.webp";

import Angie0 from "../images/execs/2/0.webp";
import Angie1 from "../images/execs/2/1.webp";
import Angie2 from "../images/execs/2/2.webp";
import Angie3 from "../images/execs/2/3.webp";
import Angie4 from "../images/execs/2/4.webp";
import Angie5 from "../images/execs/2/5.webp";
import Angie6 from "../images/execs/2/6.webp";
import Angie7 from "../images/execs/2/7.webp";
import Angie8 from "../images/execs/2/8.webp";
import Angie9 from "../images/execs/2/9.webp";

import Ali0 from "../images/execs/3/0.webp";
import Ali1 from "../images/execs/3/1.webp";
import Ali2 from "../images/execs/3/2.webp";
import Ali3 from "../images/execs/3/3.webp";
import Ali4 from "../images/execs/3/4.webp";
import Ali5 from "../images/execs/3/5.webp";
import Ali6 from "../images/execs/3/6.webp";
import Ali7 from "../images/execs/3/7.webp";
import Ali8 from "../images/execs/3/8.webp";
import Ali9 from "../images/execs/3/9.webp";

import Meriem0 from "../images/execs/4/0.webp";
import Meriem1 from "../images/execs/4/1.webp";
import Meriem2 from "../images/execs/4/2.webp";
import Meriem3 from "../images/execs/4/3.webp";
import Meriem4 from "../images/execs/4/4.webp";
import Meriem5 from "../images/execs/4/5.webp";
import Meriem6 from "../images/execs/4/6.webp";
import Meriem7 from "../images/execs/4/7.webp";
import Meriem8 from "../images/execs/4/8.webp";
import Meriem9 from "../images/execs/4/9.webp";

import Aashish0 from "../images/execs/5/0.webp";
import Aashish1 from "../images/execs/5/1.webp";
import Aashish2 from "../images/execs/5/2.webp";
import Aashish3 from "../images/execs/5/3.webp";
import Aashish4 from "../images/execs/5/4.webp";
import Aashish5 from "../images/execs/5/5.webp";
import Aashish6 from "../images/execs/5/6.webp";
import Aashish7 from "../images/execs/5/7.webp";
import Aashish8 from "../images/execs/5/8.webp";
import Aashish9 from "../images/execs/5/9.webp";

import Erik0 from "../images/execs/6/0.webp";
import Erik1 from "../images/execs/6/1.webp";
import Erik2 from "../images/execs/6/2.webp";
import Erik3 from "../images/execs/6/3.webp";
import Erik4 from "../images/execs/6/4.webp";
import Erik5 from "../images/execs/6/5.webp";
import Erik6 from "../images/execs/6/6.webp";
import Erik7 from "../images/execs/6/7.webp";
import Erik8 from "../images/execs/6/8.webp";
import Erik9 from "../images/execs/6/9.webp";

import Tara0 from "../images/execs/7/0.webp";
import Tara1 from "../images/execs/7/1.webp";
import Tara2 from "../images/execs/7/2.webp";
import Tara3 from "../images/execs/7/3.webp";
import Tara4 from "../images/execs/7/4.webp";
import Tara5 from "../images/execs/7/5.webp";
import Tara6 from "../images/execs/7/6.webp";
import Tara7 from "../images/execs/7/7.webp";
import Tara8 from "../images/execs/7/8.webp";
import Tara9 from "../images/execs/7/9.webp";

import Fay0 from "../images/execs/8/0.webp";
import Fay1 from "../images/execs/8/1.webp";
import Fay2 from "../images/execs/8/2.webp";
import Fay3 from "../images/execs/8/3.webp";
import Fay4 from "../images/execs/8/4.webp";
import Fay5 from "../images/execs/8/5.webp";
import Fay6 from "../images/execs/8/6.webp";
import Fay7 from "../images/execs/8/7.webp";
import Fay8 from "../images/execs/8/8.webp";
import Fay9 from "../images/execs/8/9.webp";

import Tolu0 from "../images/execs/9/0.webp";
import Tolu1 from "../images/execs/9/1.webp";
import Tolu2 from "../images/execs/9/2.webp";
import Tolu3 from "../images/execs/9/3.webp";
import Tolu4 from "../images/execs/9/4.webp";
import Tolu5 from "../images/execs/9/5.webp";
import Tolu6 from "../images/execs/9/6.webp";
import Tolu7 from "../images/execs/9/7.webp";
import Tolu8 from "../images/execs/9/8.webp";
import Tolu9 from "../images/execs/9/9.webp";

import RachelQ0 from "../images/execs/10/0.webp";
import RachelQ1 from "../images/execs/10/1.webp";
import RachelQ2 from "../images/execs/10/2.webp";
import RachelQ3 from "../images/execs/10/3.webp";
import RachelQ4 from "../images/execs/10/4.webp";
import RachelQ5 from "../images/execs/10/5.webp";
import RachelQ6 from "../images/execs/10/6.webp";
import RachelQ7 from "../images/execs/10/7.webp";
import RachelQ8 from "../images/execs/10/8.webp";
import RachelQ9 from "../images/execs/10/9.webp";

import RachelO0 from "../images/execs/11/0.webp";
import RachelO1 from "../images/execs/11/1.webp";
import RachelO2 from "../images/execs/11/2.webp";
import RachelO3 from "../images/execs/11/3.webp";
import RachelO4 from "../images/execs/11/4.webp";
import RachelO5 from "../images/execs/11/5.webp";
import RachelO6 from "../images/execs/11/6.webp";
import RachelO7 from "../images/execs/11/7.webp";
import RachelO8 from "../images/execs/11/8.webp";
import RachelO9 from "../images/execs/11/9.webp";

import Chelsea0 from "../images/execs/12/0.webp";
import Chelsea1 from "../images/execs/12/1.webp";
import Chelsea2 from "../images/execs/12/2.webp";
import Chelsea3 from "../images/execs/12/3.webp";
import Chelsea4 from "../images/execs/12/4.webp";
import Chelsea5 from "../images/execs/12/5.webp";
import Chelsea6 from "../images/execs/12/6.webp";
import Chelsea7 from "../images/execs/12/7.webp";
import Chelsea8 from "../images/execs/12/8.webp";
import Chelsea9 from "../images/execs/12/9.webp";

import Tim0 from "../images/execs/13/0.webp";
import Tim1 from "../images/execs/13/1.webp";
import Tim2 from "../images/execs/13/2.webp";
import Tim3 from "../images/execs/13/3.webp";
import Tim4 from "../images/execs/13/4.webp";
import Tim5 from "../images/execs/13/5.webp";
import Tim6 from "../images/execs/13/6.webp";
import Tim7 from "../images/execs/13/7.webp";
import Tim8 from "../images/execs/13/8.webp";
import Tim9 from "../images/execs/13/9.webp";

export const execMapping = new Map ([
    [1, 'Ali Raza Bhangu'],
    [2, 'Justin Wang'],
    [3, 'Angeleeca Jocson'],
    [4, 'Meriem Mostefai'],
    [5, 'Aashish Suresh'],
    [6, 'Erik Ang'],
    [7, 'Tara Denaud Joseph'],
    [8, 'Toluwanimi Emoruwa'],
    [9, 'Fay Lee'],
    [10, 'Rachel Qi'],
    [11, 'Rachel Olugbemiro'],
    [12, 'Timothy Mao'],
    [13, 'Chelsea Brown'],
]);

export const execImageMap = [
    new Map([
        [0, Ali0],
        [1, Ali1],
        [2, Ali2],
        [3, Ali3],
        [4, Ali4],
        [5, Ali5],
        [6, Ali6],
        [7, Ali7],
        [8, Ali8],
        [9, Ali9],
    ]),
    new Map([
        [0, Justin0],
        [1, Justin1],
        [2, Justin2],
        [3, Justin3],
        [4, Justin4],
        [5, Justin5],
        [6, Justin6],
        [7, Justin7],
        [8, Justin8],
        [9, Justin9],
    ]),
    new Map([
        [0, Angie0],
        [1, Angie1],
        [2, Angie2],
        [3, Angie3],
        [4, Angie4],
        [5, Angie5],
        [6, Angie6],
        [7, Angie7],
        [8, Angie8],
        [9, Angie9],
    ]),
    new Map([
        [0, Meriem0],
        [1, Meriem1],
        [2, Meriem2],
        [3, Meriem3],
        [4, Meriem4],
        [5, Meriem5],
        [6, Meriem6],
        [7, Meriem7],
        [8, Meriem8],
        [9, Meriem9],
    ]),
    new Map([
        [0, Aashish0],
        [1, Aashish1],
        [2, Aashish2],
        [3, Aashish3],
        [4, Aashish4],
        [5, Aashish5],
        [6, Aashish6],
        [7, Aashish7],
        [8, Aashish8],
        [9, Aashish9],
    ]),
    new Map([
        [0, Erik0],
        [1, Erik1],
        [2, Erik2],
        [3, Erik3],
        [4, Erik4],
        [5, Erik5],
        [6, Erik6],
        [7, Erik7],
        [8, Erik8],
        [9, Erik9],
    ]),
    new Map([
        [0, Tara0],
        [1, Tara1],
        [2, Tara2],
        [3, Tara3],
        [4, Tara4],
        [5, Tara5],
        [6, Tara6],
        [7, Tara7],
        [8, Tara8],
        [9, Tara9],
    ]),
    new Map([
        [0, Tolu0],
        [1, Tolu1],
        [2, Tolu2],
        [3, Tolu3],
        [4, Tolu4],
        [5, Tolu5],
        [6, Tolu6],
        [7, Tolu7],
        [8, Tolu8],
        [9, Tolu9],
    ]),
    new Map([
        [0, Fay0],
        [1, Fay1],
        [2, Fay2],
        [3, Fay3],
        [4, Fay4],
        [5, Fay5],
        [6, Fay6],
        [7, Fay7],
        [8, Fay8],
        [9, Fay9],
    ]),
    new Map([
        [0, RachelQ0],
        [1, RachelQ1],
        [2, RachelQ2],
        [3, RachelQ3],
        [4, RachelQ4],
        [5, RachelQ5],
        [6, RachelQ6],
        [7, RachelQ7],
        [8, RachelQ8],
        [9, RachelQ9],
    ]),
    new Map([
        [0, RachelO0],
        [1, RachelO1],
        [2, RachelO2],
        [3, RachelO3],
        [4, RachelO4],
        [5, RachelO5],
        [6, RachelO6],
        [7, RachelO7],
        [8, RachelO8],
        [9, RachelO9],
    ]),
    new Map([
        [0, Tim0],
        [1, Tim1],
        [2, Tim2],
        [3, Tim3],
        [4, Tim4],
        [5, Tim5],
        [6, Tim6],
        [7, Tim7],
        [8, Tim8],
        [9, Tim9],
    ]),
    new Map([
        [0, Chelsea0],
        [1, Chelsea1],
        [2, Chelsea2],
        [3, Chelsea3],
        [4, Chelsea4],
        [5, Chelsea5],
        [6, Chelsea6],
        [7, Chelsea7],
        [8, Chelsea8],
        [9, Chelsea9],
    ]),
];